<template>
  <div coach-sales-detail>
    <div class="inner">
      <ColorButton class="prev-btn" type="light-gray" @click="prevBtnHandler">
        <svg-angle />
      </ColorButton>
      <div class="list">
        <AccordionList>
          <template #title><h1>판매 상세</h1></template>
          <template #list>
            <AccordionListItem>
              <p>주문 번호</p>
              <p>{{ _info.orderNumber }}</p>
            </AccordionListItem>
            <AccordionListItem>
              <p>주문 상태</p>
              <StatusBadgeSales :value="_info.status" />
            </AccordionListItem>
            <AccordionListItem>
              <p>주문 완료일</p>
              <p>{{ formatDate(_info.orderDateTime) }}</p>
            </AccordionListItem>
            <AccordionListItem>
              <p>주문 취소일</p>
              <p>{{ formatDate(_info.refundDateTime) }}</p>
            </AccordionListItem>
            <AccordionListItem>
              <p>주문 포인트</p>
              <em>{{ `${formatPrice(_info.price)} UP` }}</em>
            </AccordionListItem>
            <AccordionListItem>
              <p>쿠폰 포인트</p>
              <em>{{ `${formatPrice(_info.couponPrice)} UP` }}</em>
            </AccordionListItem>
            <AccordionListItem>
              <p>환불 포인트</p>
              <em>{{ `${formatPrice(_info.refundPrice)} UP` }}</em>
            </AccordionListItem>
          </template>
        </AccordionList>
        <AccordionList useAccordion>
          <template #title><h2>고객 정보</h2></template>
          <template #list>
            <AccordionListItem>
              <p>고객 닉네임</p>
              <p>{{ customerNickname }}</p>
            </AccordionListItem>
          </template>
        </AccordionList>
        <AccordionList useAccordion>
          <template #title><h2>상품 정보</h2></template>
          <template #list>
            <AccordionListItem>
              <p>상품 번호</p>
              <p>{{ _info.orderNumber }}</p>
            </AccordionListItem>
            <AccordionListItem>
              <p>상품 유형</p>
              <p>{{ formatLessonType(_info.productType) }}</p>
            </AccordionListItem>
            <AccordionListItem>
              <p>상품명</p>
              <p>{{ _info.name }}</p>
            </AccordionListItem>
            <AccordionListItem>
              <p>상품 수량</p>
              <p>{{ productAmount(_info.bundleSize) }}</p>
            </AccordionListItem>
          </template>
        </AccordionList>
        <AccordionList useAccordion>
          <template #title><h2>주문 메모</h2></template>
          <template #list>
            <ul class="input-tip">
              <li>고객은 코치님의 주문 메모를 확인할 수 없습니다.</li>
              <li>주문과 관련하여 기억하고 싶은 내용을 자유롭게 편집할 수 있습니다.</li>
              <li>예시) "브론즈1 고객님과의 첫 수업이 떨렸지만 리뷰 약속도 해주셨다. 뿌듯하다."</li>
            </ul>
            <ValidationObserver ref="validator">
              <ValidationProvider rules="required|lengthMin:2|lengthMax:400" name="questionAnswer" v-slot="{errors}">
                <memo-text-area @focus="textAreaFocus" v-model="editText" :min-length="2" :max-length="400" class="memo-input" :class="{error:errors.length >= 1}" />
                <div class="btn-wrap">
                  <color-button class="btn-memo" :type="memoBtnStatus === 'saving' ? 'gray' : 'dark'" @click="postMemo"
                    :disabled="memoNotClicked || editText.length === 0 || errors.length >= 1">
                    <Lottie v-show="memoBtnStatus === 'saving'" src="/lottie/loading.json" class="lottie" autoplay loop />
                    <Lottie ref="lottieComplete" v-show="memoBtnStatus === 'done'" src="/lottie/complete.json" class="lottie" />
                    <span v-if="memoBtnStatus === 'ready'">메모 저장</span>
                    <span v-else-if="memoBtnStatus === 'saving'">
                      저장 중
                    </span>
                    <span v-else-if="memoBtnStatus === 'done'">
                      저장 완료
                    </span>
                  </color-button>
                </div>
              </ValidationProvider>
            </ValidationObserver>
          </template>
        </AccordionList>
      </div>
    </div>
  </div>
</template>

<script>
import ColorButton from 'shared/components/common/ColorButton.vue';
import SvgAngle from '@shared/graphics/svg-angle.vue';
import { commaDecimal } from 'shared/utils/numberUtils';
import { sleep } from '@shared/utils/commonUtils';
import Lottie from '@shared/components/general/Lottie.vue';
import StatusBadgeSales from '@/views/components/coaching/sales/StatusBadgeSales.vue';
import MemoTextArea from '@/views/components/common/input/MemoTextArea.vue';
import AccordionList from '@/views/components/coaching/sales/AccordionList.vue';
import AccordionListItem from '@/views/components/coaching/sales/AccordionListItem.vue';

export default {
  name: 'CoachSalesDetail.vue',
  lexicon: 'coaching.salesHistory',
  components: { SvgAngle, StatusBadgeSales, AccordionListItem, AccordionList, ColorButton, MemoTextArea, Lottie },
  data: () => ({
    info: {},
    editText: '',
    memoBtnStatus: 'ready',
    memoNotClicked: true,
  }),
  mounted() {
    if (this.info.memo) this.editText = this.info.memo.description;
  },
  computed: {
    _info() {
      return /** type {SalesLessonOrder} */ this.info;
    },
    customerNickname() {
      return this._info?.customer?.nickname;
    },
  },
  methods: {
    formatDate(timestamp) {
      if (!timestamp) return '-';
      return this.$date(timestamp, 'datetimeNoneMeridiem');
    },
    formatPrice(price) {
      if (!price) return '0';
      return commaDecimal(price);
    },
    formatLessonType(type) {
      if (type === 'LESSON') return '수업';
    },
    productAmount(amount) {
      return this.$t('_.amount', [amount]);
    },
    prevBtnHandler() {
      this.$router.go(-1);
    },
    async postMemo() {
      if (this.memoBtnStatus === 'ready') {
        this.memoBtnStatus = 'saving';
        if (this._info.memo) {
          await this.$services.coaching.putOrderMemo({ productOrderId: this.$route.params.lessonProductOrderId, description: this.editText });
        } else {
          await this.$services.coaching.postOrderMemo({ productOrderId: this.$route.params.lessonProductOrderId, description: this.editText });
          this._info.memo = this.editText;
        }
        await sleep(1000);
        this.memoBtnStatus = 'done';
        this.$refs.lottieComplete.play();
      }
    },
    textAreaFocus() {
      this.memoBtnStatus = 'ready';
      this.memoNotClicked = false;
    },
  },
  async asyncData({ services, route }) {
    const { lessonProductOrderId } = route.params;
    const info = await services.coaching.getSalesLessonOrder({ lessonProductOrderId });
    return { info };
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coach-sales-detail] { .bgc(#fff);
  .prev-btn {.w(40); .h(40); transform: rotate(90deg);}
  .btn-wrap {.tr}
  .btn-memo {.w(117); .h(40); .mt(12);}
  .inner { .max-w(1260); .m(0, auto);.pt(52); .pb(120); }
  .list {.pt(28); .max-w(908); .m(0, auto);}
  .lottie {.fl;.w(18px);.h(18);.mr(-5);.ml(10);.mt(3)}
  .input-tip {.p(5, 4, 9, 4);.c(#a4a3ae);}
  .input-tip > li { .ml(13);.rel;.lh(20.72);.fs(14)}
  .input-tip > li::before {content: '';.bgc(#a4a3ae);.wh(4, 4);.br(100%);.abs;.l(-12);.t(8)}
  @media (@ds-down) {
    .inner { .p(58);}
  }

  @media (@ml-down) {
    .inner {.p(20);}
  }
}
</style>
